export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

export const validatorTaxNumber = (value, cc) => {

  if(!value.length || !cc.length){
    return true;
  }

  const regexps = {
    'AT': /^(U\d{8}$)/,                                   // Austria
    'BE': /^(\d{10}$)/,                                   // Belgium
    'BG': /^(\d{9,10}$)/,                                 // Bulgaria
    'CY': /^([0-5|9]\d{7}[A-Z]$)/,                        // Cyprus
    'CZ': /^(\d{8,10})?$/,                                // Czech Republic
    'DE': /^([1-9]\d{8}$)/,                               // Germany
    'DK': /^(\d{8}$)/,                                    // Denmark
    'EE': /^(10\d{7}$)/,                                  // Estonia
    'EL': /^(\d{9}$)/,                                    // Greece
    'ES': /^([0-9A-Z][0-9]{7}[0-9A-Z]$)/,                 // Spain
    'EU': /^(\d{9}$)/,                                    // EU-type
    'FI': /^(\d{8}$)/,                                    // Finland
    'FR': /^([0-9A-Z]{2}[0-9]{9}$)/,                      // France
    'GB': /^((?:[0-9]{12}|[0-9]{9}|(?:GD|HA)[0-9]{3})$)/, // UK (Standard = 9 digits), (Branches = 12 digits), (Government = GD + 3 digits), (Health authority = HA + 3 digits)
    'GR': /^(\d{8,9}$)/,                                  // Greece
    'HR': /^(\d{11}$)/,                                   // Croatia
    'HU': /^[0-9]{8}-[0-9]-[0-9]{2}$/,                                    // Hungary
    'IE': /^([0-9A-Z\*\+]{7}[A-Z]{1,2}$)/,                // Ireland
    'IT': /^(\d{11}$)/,                                   // Italy
    'LV': /^(\d{11}$)/,                                   // Latvia
    'LT': /^(\d{9}$|\d{12}$)/,                            // Lithunia
    'LU': /^(\d{8}$)/,                                    // Luxembourg
    'MT': /^([1-9]\d{7}$)/,                               // Malta
    'NL': /^(\d{9}B\d{2}$)/,                              // Netherlands
    'NO': /^(\d{9}$)/,                                    // Norway (not EU)
    'PL': /^(\d{10}$)/,                                   // Poland
    'PT': /^(\d{9}$)/,                                    // Portugal
    'RO': /^([1-9]\d{1,9}$)/,                             // Romania
    'RU': /^(\d{10}$|\d{12}$)/,                           // Russia
    'RS': /^(\d{9}$)/,                                    // Serbia
    'SI': /^([1-9]\d{7}$)/,                               // Slovenia
    'SK': /^([1-9]\d[(2-4)|(6-9)]\d{7}$)/,                // Slovakia Republic
    'SE': /^(\d{10}01$)/                                  // Sweden
  }

  if(Object.keys(regexps).includes(cc[0])){
    const taxNumberRegex = regexps[cc[0]];
    const validTaxNumber = taxNumberRegex.test(value)
    return validTaxNumber
  }

  return false
}
